import React, { FC, useCallback, useReducer, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { LineReducer, LineSetContext, LineUI, useModal, usePoll, useMediaModal, Spinner} from 'scorer-ui-kit';
import { LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import styled, { css } from 'styled-components';
import MediaModal from './MediaModal';
import i18n from 'i18n';
import AwaitingStreamEn from '../svg/img_awaiting_stream_en.jpg';
import AwaitingStreamJp from '../svg/img_awaiting_stream_jp.jpg';

const Container = styled.div<{ disabled: boolean }>`
  border-radius: 5px;
  display: grid;
  height: 220px;
  width: 300px;
  position: relative;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
  `}
  @media (max-width:1438px) and (min-width:1280px) {
    width:48.7%;
  }
`;

const ImageBox = styled.div<{ showPointer:boolean }>`
  width: 300px;
  position: relative;
  background: #ddd;
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 1439px) {
    width: 300px;
  }
  height: 169px;
  border-radius: 5px;
  > div {
    height: inherit;
    > img {
      height: inherit;
      border-radius: 5px;
    }
  }
  ${({ showPointer }) => showPointer && css`
    cursor: pointer;
  `}
`;

const DetailsBox = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 56px;
  background: white;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  @media (max-width:1438px) and (min-width:1280px) {
    // min-width: 400px;
    box-shadow: 1px 1px #cccc;
  }
  // border-bottom-left-radius: 5px;   
  // border-bottom-right-radius: 5px;
  // display: grid;
  // grid-template-columns: 30% 30% 40%;
  // grid-template-rows: 50% 50%;
`;




const LabelText = styled.p`
  font-size: 16px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  margin: 0px;
`;




const CameraTextBox = styled.div`
  flex: 1;
  cursor: pointer;
  pointer-events: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SpinnerContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  ${({ backgroundColor }) => css`
    background-color: ${backgroundColor? backgroundColor: '#a6a6a6'}
  `};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  @media (max-width:1438px) and (min-width:1280px) {
    height: 253px;
  }
`;

const FormatterSvg = styled.div`
  @media (max-width:1438px) and (min-width:1280px) {
    svg {
      width: 90%;
    }
  }
  display: flex;
    justify-content: space-around;
`;

const ImageNone = styled.img`
  height: 300px;
  object-fit: cover;
`;



interface ICard {
  options?: LineUIOptions;
  data: any;
}

interface IImageResponse {
  data: ArrayBuffer,
  status: number,
}

const Card: FC<ICard> = ({ options = {}, data }) => {
  const [state, dispatch] = useReducer(LineReducer, []);
  const [image, setImage] = useState('');
  const [imageDetails, setImageDetails] = useState({x: 1920, y: 1080});
  const [noImage, setNoImage] = useState(false);
  const { t } = useTranslation(['CommonDict']);
  const { createModal, setModalOpen } = useModal();
  const { isMediaUrlValid } = useMediaModal();
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const lang = i18n.language === 'ja' ? 'ja' : 'en';


  const getCameraImage = useCallback(async () => {
    try {
      const res: IImageResponse = await axios.get(`${data.edge_api_url}/api/v1/stacks/${data.stream_name}/snapshot?timestamp=${Date.now()}`, { responseType: 'arraybuffer' });
      if (res.status === 200 && res.data) {
        if (res.data.byteLength > 5) {
          const imgBase64 = 'data:image/jpg;base64,' + Buffer.from(res.data).toString('base64');
          const isImageValid = await isMediaUrlValid(imgBase64, 'img');
          if (isImageValid === true) {
            const img = new Image();
            img.src = imgBase64;
            setImage(imgBase64);
            setImageDetails({
              x: img.naturalWidth,
              y: img.naturalHeight
            });
            setNoImage(false);
            setImageLoading(false);
          }
        } else {
          setImage('');
          setNoImage(true);
          setImageLoading(false);
        }
      } else {
        setNoImage(true);
        setImageLoading(false);
        setImageLoading(false);
      }
    } catch (err) {
      setNoImage(true);
      console.error(err);
    }
  }, [data, isMediaUrlValid]);

  usePoll(async () => {
    await getCameraImage();
  }, (1000 * 60));
  


  const openMediaModel = useCallback((streamName: string) => {
    const EDGE_API_BASE_URL = data.edge_api_url;
    createModal({
      isCloseEnable: true,
      width: '60%',
      padding: false,
      closeText: t('CLOSE'),
      dismissCallback: () => { setModalOpen(false); },
      //customComponent: (<MediaModal {...{ state, dispatch, image, options, streamName }} bufferTime={(data?.camera_type === 'RTSP' ? data?.video_configuration?.buffer_time : LINE_VIEWER_CAMERA_IMAGE_REFRESH_INTERVAL) * 1000} />)
      customComponent: (<MediaModal {...{ state, dispatch, image, options, streamName, EDGE_API_BASE_URL }} bufferTime={1000 * 60} />)
    
    });
  }, [data, state, dispatch, image, options, createModal, setModalOpen, t]);


  return (
    <Container disabled={data.status_code === 20100}>
      <ImageBox
        showPointer={!noImage}
        onClick={() => { (!noImage) && openMediaModel(data.stream_name); }}
      >
        {noImage ?
          <FormatterSvg>
            {lang==='ja' ?
              <ImageNone src={AwaitingStreamJp} /> : <ImageNone src={AwaitingStreamEn} />}
          </FormatterSvg>
          : 
          <>
            {imageLoading ?
              <SpinnerContainer>
                <Spinner size='large' styling='primary' />
              </SpinnerContainer> :
              <LineSetContext.Provider value={{ state, dispatch }}>
                <LineUI 
                  options={{...options, boundaryOffset: Math.round(imageDetails.x * 2.5 / 100)}} 
                  src={image} 
                  hasClickSensingBorder={false}
                />
              </LineSetContext.Provider>}
          </>}
      </ImageBox>
      <DetailsBox>
        <CameraTextBox onClick={() => { (!noImage) && openMediaModel(data.stream_name); }}>
          <LabelText title={t(data?.camera_name)}>{t(data?.camera_name)}</LabelText>
        </CameraTextBox>
      </DetailsBox>
    </Container>
  );
};

export default Card;