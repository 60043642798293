import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AlertBar, Button, Icon, Label, PageTitle, Spinner, TextField, useNotification, TypeTable } from 'scorer-ui-kit';
import { getParkingHistory } from 'services/apiConfig';
import AuthService from 'services/authService';
import { Pagination } from 'antd';
import { dateFormat } from 'utils';
import DateInput from 'components/DateInput';
import { format, parseISO } from 'date-fns';
import {DATE_FORMAT_WITH_SEC } from '../constants';
import { ITableColumnConfig } from 'scorer-ui-kit/dist/Tables';


const Container = styled.div`
  margin: 50px;
`;

const WrapperInput = styled.div`
  width: 380px;
  max-width: 380px;
  flex-grow: 0;
  flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  display: flex;
  align-items: center;
`;

const TextFieldWrapper = styled.div`
  width: 80%;
`;


const StyledButton = styled(Button)`
  margin: 30px 10px;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
  row-gap: 20px;
  height: auto;
  align-items: center;
`;

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 10px;

`;

const PageHeaderFormatter = styled.div`
  svg {
    margin-top: -7px;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin: 9px 0 41px 2px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 9px;
  align-items: center;
`;

const SubTitle = styled.label`
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: hsl(207,5%,57%);
  margin: 0;
  padding: 0;
`;

const NoDataContainer = styled.div`
  margin-top: 20px;
`;

const SearchIconWrapper = styled.div`
  margin: 16px 0px 0px -31px;
  z-index: 1;
  width: 20%;
`;

const WrapTypeTable = styled.div`  
  & > div > div > div > div > div > div {
    font-size: 18px;
    font-weight: 600;
  }
  & > div > div > div > div {
    font-size: 16px;
    font-weight: 400;
  }
`;

interface IInterval {
  start: Date,
  end: Date
}


const columnConfig: ITableColumnConfig[] = [
  {
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'place',
    header: '駐車場名',
  },
  {
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'area',
    header: '地域',
  },
  {
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'user_type',
    header: '種別',
  },
  {
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'kana',
    header: 'かな',
  },
  {
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'number',
    header: '車番',
  },
  {
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'camera_name',
    header: '検知カメラ',
  },
  /*{
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'label_name',
    header: '入場検知線',
  },*/
  {
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'counted_at',
    header: '時刻',
  },
  {
    alignment: 'center',
    cellStyle: 'normalImportance',
    columnId: 'type',
    header: '向き',
  }              
];


const EntranceExit: FC = () => {
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);

  const [searchTerm, setSearchTerm] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);

  const [parkingHistoryData, setParkingHistoryData] = useState<any>([]);
  const [data, setData] = useState<any>(parkingHistoryData);
  const [pageContent, setPageContent] = useState<any>([]);


  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const startDateInit = dateFormat(new Date());
  startDateInit.setDate(startDateInit.getDate() - 1);
  const [defaultDate,] = useState<IInterval>({ start: startDateInit, end: dateFormat(new Date()) });

  const [startDate, setStartDate] = useState<Date>(defaultDate.start);
  const [endDate, setEndDate] = useState<Date>(defaultDate.end);
  const [tempStartDate, setTempStartDate] = useState<any>(defaultDate.start);
  const [tempEndDate, setTempEndDate] = useState<any>(defaultDate.end);


  const fetchParkingHistory = useCallback(async () => {
    setIsDataFetched(false);
    setLoading(true);
    const startDateVal = format(startDate, DATE_FORMAT_WITH_SEC);
    const endDateVal = format(endDate, DATE_FORMAT_WITH_SEC);
    try {      
      const { data: { status_code, data } } = await getParkingHistory({/*currentPage: currentPage, pageSize: pageSize, */startDateVal: startDateVal, endDateVal: endDateVal});
      if (status_code === 200) {
        setParkingHistoryData(data.data);
        setTotalRecords(data.totalRecords);
        setCurrentPage(1);
        setLoading(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        setLoading(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load parking history data') });
      }
    } catch (error) {
      setLoading(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to load parking history data') });
      console.error((error as Error).message);
    }
    setIsDataFetched(true);
  }, [/*currentPage, pageSize,*/ startDate, endDate]);

  useEffect(() => {
    fetchParkingHistory();
  }, [fetchParkingHistory]);


  const updateStartDate = useCallback((date) => {    
    if (tempEndDate.getTime() - 60000 <= date.getTime() || date > dateFormat(new Date())) {
      notificationRef.current({ type: 'error', message: t('Please select \'From\' date smaller then now date and \'To\' date. ') });
      return;
    }
    setTempStartDate(date);
  }, [t, tempEndDate]);


  const updateEndDate = useCallback((date) => {
    if (tempStartDate >= date || date > dateFormat(new Date())) {
      notificationRef.current({ type: 'error', message: t('Please select \'To\' date smaller then now and greater then \'From\' date. ') });
      return;
    }
    setTempEndDate(date);
  }, [t, tempStartDate]);
  

  const handleSearchByDateTime = () => {
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };
  

  // 文字例フィルター
  useEffect(() => {
    if(searchTerm) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase(); 
      const typeMapping: any = {
        1: '入場',
        2: '退場'
      };
      const filtered = parkingHistoryData.filter((item: any) => {  
  
        const placeMatch = item.place?.toLowerCase()?.includes(lowerCaseSearchTerm) || false;
        const areaMatch = item.area?.toLowerCase()?.includes(lowerCaseSearchTerm) || false;
        const userTypeMatch = item.user_type?.toLowerCase()?.includes(lowerCaseSearchTerm) || false;          
        const kanaMatch = item.kana?.toLowerCase()?.includes(lowerCaseSearchTerm) || false;    
        
        const numberAsString = typeof item.number === 'number' ? item.number.toString() : item.number;
        const numberMatch = (typeof numberAsString === 'string' ? numberAsString.toLowerCase() : numberAsString)?.includes(lowerCaseSearchTerm) || false;
        
        const inLabelNameMatch = item.in_label_name?.toLowerCase()?.includes(lowerCaseSearchTerm) || false;          
        const labelNameMatch = item.label_name?.toLowerCase()?.includes(lowerCaseSearchTerm) || false;
        
        const typeAsString = typeof item.type === 'number' ? item.type.toString() : item.type;
        const typeDisplay = typeMapping[typeAsString] || typeAsString;  
        const typeMatch = typeDisplay.toLowerCase().includes(lowerCaseSearchTerm);
    
        return placeMatch || areaMatch || userTypeMatch || kanaMatch || numberMatch || inLabelNameMatch || labelNameMatch || typeMatch;
  
      });
      setData(filtered);
      setTotalRecords(filtered.length);
    } else {
      setData(parkingHistoryData);
    }

  },[parkingHistoryData, searchTerm]);

  

  const handleSearchTextChange = (event: any) => {
    setSearchTerm(event.target.value);
  };


  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  


  useEffect(() => {
    if(data.length > 0){
      const arrData = [];
      const copiedObj = [...data].slice((currentPage - 1) * pageSize, currentPage * pageSize);
      for (let i = 0; i < copiedObj.length; i++) {
        arrData.push({columns: [
          { text: copiedObj[i].place },
          { text: copiedObj[i].area },
          { text: copiedObj[i].user_type },
          { text: copiedObj[i].kana },
          { text: copiedObj[i].number },
          { text: copiedObj[i].camera_name },
          { text: copiedObj[i].counted_at },
          { text: copiedObj[i].type === 1 ? '入場' : '退場' },
        ], id: `row${i+1}`});
      } 
      setPageContent(arrData);
    } else {
      setPageContent([{ columns: [] }]);
    }
  },[data, currentPage, pageSize]);








  if (loading) {
    return (
      <SpinnerBox>
        <Spinner size='large' styling='primary' />
        <Label htmlFor='loader' labelText={t('Loading') + '...'} />
      </SpinnerBox>
    );
  }

  return (
    <>
      {isDataFetched ? (
        <Container>
          <PageHeaderFormatter>
            <PageTitle
              areaHref=''
              areaTitle=''
              icon='FilterSorting'
              title={t('Entrance/Exit Detection Result List')}
            />
          </PageHeaderFormatter>

          <FlexContainer>
            <SubTitle>{t('Time Range Filter')}</SubTitle>
          </FlexContainer>
          <FlexColumn>
            <DateInput labelText={t('From Date Time')} date={tempStartDate} callBackFunction={updateStartDate} />
            <DateInput labelText={t('To Date Time')} date={tempEndDate} callBackFunction={updateEndDate} />
            <StyledButton size='small' onClick={handleSearchByDateTime}>{t('Search')}</StyledButton>
          </FlexColumn>

          <WrapperInput>
            <TextFieldWrapper>
              <TextField
                feedbackMessage='This is a feedback message.'
                fieldState='default'
                label={t('Search By Character')}
                name='filter'
                placeholder={t('Keywords Search')}
                onChange={handleSearchTextChange}
              />
            </TextFieldWrapper>
            <SearchIconWrapper>
              <Icon icon='Search' size={17} color='primary' />
            </SearchIconWrapper>
          </WrapperInput>

          <WrapTypeTable>
            <TypeTable
              columnConfig={columnConfig}
              rows={pageContent}
            />
          </WrapTypeTable>

          <NoDataContainer>
            {(!parkingHistoryData.length) ? 
              <AlertBar type='info' message={t('この期間はデータがありません。')} />
              : (!data.length) ? 
                <AlertBar type='info' message={t('一致するデータがありません')} />
                : ''}
          </NoDataContainer>

          <StyledPagination 
            current={currentPage} 
            total={totalRecords}
            pageSize={pageSize}
            onChange={handlePageChange}
            onShowSizeChange={handlePageSizeChange}
            showSizeChanger
            pageSizeOptions={[10, 25, 50]}
            showTotal={(total) => `${t('Total')} ${total} ${t('records')}`}
          />
        </Container>
      ) : (
        <SpinnerContainer>
          <Spinner size='medium' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerContainer>
      )}
    </>
  );
};

export default EntranceExit;