import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Content, DateInterval, FilterBar, IFilterItem, IFilterResult, ISearchFilter, Label, PageTitle, Spinner } from 'scorer-ui-kit';
import styled from 'styled-components';
import Card from 'components/Card';
import { SpinnerContainer } from 'style';
import { getAllCamera } from 'services/apiConfig';

const Container = styled(Content)`
  @media only screen and (max-width: 1440px) {
    max-width: 940px;
  }
  & > div {
    max-width: 940px;
  }
  user-select: none;
  padding: 70px 150px;
`;

const HeaderBar = styled.div`
  @media only screen and (width: 1920px) {
    max-width: 1421px !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > div > div > div{
    bottom: 2px;
  }
`;

const FilterContainer = styled.div`
  @media only screen and (width: 1920px) {
    min-width: 940px !important;
  }
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px 0 43px 0px;
  & > div > div > div:nth-child(2) > div{
    max-width: 214px;
  }
  & > div > div > div:nth-child(3){
    margin-top: 15px;
    margin-right: 233px;
  }
`;

const FilterWrapper = styled.div<{pointerEvent: string, background:string} >`
  flex-grow: 1;
  margin-top: 14px;
  button {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
  input {
    opacity:${({background}) => background};
    pointer-events: ${({pointerEvent}) => pointerEvent};
  }
`;

const CameraListBox = styled.div`
  @media only screen and (width: 1920px) {
    min-width: 940px !important;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  border: solid 1px #eee;
  padding: 37px 0;
  @media only screen and (min-width: 1439px) {
    max-width: 1422px !important;
  }
`;

const EmptyStateTitle = styled(Label)`
  font-size: 20px;
  margin-bottom: 0;
`;



interface IFilterData {
  name: string;
  status: string;
  paramsLoaded: string;
  [key: string]: string;
}

interface INewParams {
  searchText?: string | null;
  status?: string | null;
  sortBy?: string | null;
}

interface IData {
  camera_enabled: boolean;
  edge_api_url: string;
  camera_name: string;
  camera_type: string;
  line_configuration: [];
  length: number;
  notes: string;
  status_code: number;
  status_message: string;
  stream_name: string;
  video_configuration:{};
  status: {
    status_category: string;
  }
  graph_data: [];
}

const statusSort: {[key: string]: string} = {
  Running: '1',
  Error: '2',
  Warning: '3',
  Disabled: '4'
};

const FILTER_DATA: IFilterData = { name: '', status: '', paramsLoaded: '' };

const Camera: FC = () => {
  const { replace } = useHistory();
  const { t } = useTranslation(['CommonDict']);

  const params = useLocation().search;
  const [historyParams] = useState(params);
  const [allCameraData, setAllCameraData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterValues, setFilterValues] = useState({ ...FILTER_DATA });
  const [sorting, setSorting] = useState({ value: 'name', ascending: false, text: 'Name' });
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);
  const [fetchAllCameraPollTime, setFetchAllCameraPollTime] = useState(1000 * 1);


  const getSearchConfig = useCallback((): ISearchFilter[] => {
    return [
      {
        id: 'name',
        placeholder: t('Filter by name...'),
        name: t('Name'),
        selected: filterValues.name ? { text: filterValues.name, value: filterValues.name } : undefined
      }
    ];
  }, [filterValues.name, t]);


  const fetchAllCamera = useCallback(async () => {
    try {
      const { data: { status_code, data } } = await getAllCamera();
      if (status_code === 200) {
        setAllCameraData(data);
        setIsDataFetched(data.length !== 0);
      }else{
        setAllCameraData([]);
        setIsDataFetched(false);
      }
      setLoading(false);
    } catch (error) {
      console.error((error as Error).message);
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDataFetched){
        setLoading(true);
        setIsDataFetched(false);
      }
      fetchAllCamera();
    }, fetchAllCameraPollTime);
    return () => clearInterval(interval);
  }, [fetchAllCameraPollTime, isDataFetched, fetchAllCamera]);
  useEffect(() => {
    setFetchAllCameraPollTime(allCameraData.length !== 0 ? 1000 * 60 : 1000 * 1);
  }, [allCameraData]);



  const handleFilter = useCallback((res: IFilterResult[]) => {
    setLoading(true);
    const searchFilter = { ...FILTER_DATA };
    res.forEach((item) => {
      searchFilter[item.id] = (item.selected as IFilterItem).value as string;
    });
    setFilterValues({ ...searchFilter, paramsLoaded: 'true' });
    setLoading(false);
  }, []);

  const setParams = useCallback(() => {
    const params = '?' +
      `${filterValues.name === '' ? '' : '&searchText=' + filterValues.name}` +
      `${filterValues.status === '' ? '' : '&status=' + filterValues.status}` +
      `${!sorting.ascending && sorting.value === 'name' ? '' : '&sortBy=' + JSON.stringify(sorting)}`;

    if (params === '?') {
      return '';
    }
    return params;
  }, [filterValues, sorting]);

  useEffect(() => {
    replace(window.location.pathname + setParams());
  }, [replace, setParams, filterValues]);


  const fetchHistoryParams = useCallback(() => {
    const params = new URLSearchParams(historyParams);
    const newParams: INewParams = {};
    newParams.searchText = params.get('searchText');
    newParams.status = params.get('status');
    newParams.sortBy = params.get('sortBy');

    const searchFilter = { ...FILTER_DATA };
    searchFilter.name = newParams.searchText ?? '';
    searchFilter.status = newParams.status ?? '';
    setSorting(prev => (newParams.sortBy ? JSON.parse(newParams.sortBy) : prev));

    searchFilter.paramsLoaded = 'true';
    setFilterValues(searchFilter);
  }, [historyParams]);

  useEffect(() => {
    fetchHistoryParams();
  }, [fetchHistoryParams]);


  const compare = useCallback((a, b) => {
    let result: number = 0;
    switch (sorting.value) {
    case 'name':
      result = sorting.ascending ? a.camera_name.localeCompare(b.camera_name, 'en', { numeric: true }) : b.camera_name.localeCompare(a.camera_name, 'en', { numeric: true });
      break;
    case 'status':
      result = sorting.ascending ? statusSort[a.status.status_category]?.toString().localeCompare(statusSort[b.status.status_category]) : statusSort[b.status.status_category]?.toString().localeCompare(statusSort[a.status.status_category]);
      break;
    default:
      result = 0;
    }
    return result;
  }, [sorting]);

  const filterCameraData = useCallback((data = []) => {
    const filterData = data.filter((item: IData) => {
      if (((item.camera_name.toLowerCase().includes(filterValues.name.toLowerCase())) || filterValues.name.length === 0) && (item.status.status_category.toLowerCase().includes(filterValues.status.toLowerCase()))) {
        return item;
      } else {
        return false;
      }
    });
    return filterData.sort(compare);
  }, [filterValues, compare]);

  useEffect(() => {
    const data = filterCameraData(allCameraData);
    setFilterData(data);
  }, [filterCameraData, allCameraData, filterValues]);


  return (
    <>
      {(isDataFetched && !loading && allCameraData.length !== 0) ? (
        <Container>
          <HeaderBar>
            <div>
              <PageTitle
                icon='DevicesScorerCamera'
                title={t('Cameras')}
                updateDocTitle={false}
              />
            </div>
          </HeaderBar>

          <FilterContainer>
            <FilterWrapper pointerEvent={allCameraData.length === 0 ? 'none' : 'auto'} background={allCameraData.length === 0  ? '0.5' : ''}>
              {filterValues.paramsLoaded === 'true' &&
                <FilterBar
                  resultTextTemplate={`${t('Showing All')} (${filterData.length}):`} filtersTitle={t('Filters') + ':'}
                  totalResults={filterData.length} searchersConfig={getSearchConfig()} onChangeCallback={handleFilter}
                  clearText={t('CLEAR ALL')}
                />}
            </FilterWrapper>
          </FilterContainer>

          {filterData.length > 0 ?
            <CameraListBox>
              {filterData.map((item: IData, index: number) => (
                <Card key={index} data={item} />
              ))}
            </CameraListBox>
            :
            (filterValues.name.trim() !== '' || filterValues.status.trim() !== '' ?  
              <EmptyBox>
                <EmptyStateTitle htmlFor='' labelText={t('No camera found')} />
              </EmptyBox>
              :
              <EmptyBox>
                <EmptyStateTitle htmlFor='' labelText={t('No camera found')} />
              </EmptyBox>)}
        </Container>
      ) : (
        <SpinnerContainer>
          <Spinner size='medium' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerContainer>
      )}
    </>

  );
};

export default Camera;