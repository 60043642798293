import React, { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory, useLocation } from 'react-router-dom';
import { Content, PageHeader, Switch, TextField, Button, SmallInput, Checkbox, Icon, AlertBar, TypeTable} from 'scorer-ui-kit';
import styled from 'styled-components';
import { searchResult, checkIgnore } from 'services/apiConfig';
import { TimePicker, Pagination } from 'antd';
import type { PaginationProps } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import DateInput from '../components/DateInput';
import { dateFormat } from '../utils/index';
import format from 'date-fns/format';
import {DATE_FORMAT_WITH_SEC } from '../constants';

const Container = styled(Content)`
`;

const HeaderContainer = styled.div`
  margin-bottom: 47px;
  > div:first-child > div > h1 {
    max-width: 650px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    min-width: 200px !important;
  }
  & > div > div > div:nth-child(3){
    bottom: 4px;
  }
  & > div > p {
    font-weight: normal;
    font-style: normal;
  }
  position: relative;
  max-width: 940px !important;
`;

const InnerContainer = styled.div`
  margin-left: 1px;
`;


const PageHeaderFormatter = styled.div`
  svg {
    margin-top: -7px;
  }
`;




const FlexColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 2px;
`;

const LabelText = styled.div`
  font-size: 14px;
  width: 200px;
  font-weight: 500;
  color: rgba(120, 138, 144, 0.72);
  margin: 0 25px 0 0;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  padding: 0;
  line-height: 1.4;
`;

const WrapperInput = styled.div`
  width: 380px;
  max-width: 380px;
  flex-grow: 0;
  flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  display: flex;
  align-items: center;
`;

const TextFieldWrapper = styled.div`
  width: 80%;
`;

const SearchIconWrapper = styled.div`
  margin: 0px 0px 0px -31px;
  z-index: 1;
  width: 20%;
`;


const LastBox = styled.div`
  margin-top: 8px;
  position: absolute;
  right: 10px;
  top: 21px;
`;

// const ResTbl = styled(Table)`
//   overflow-x:auto;
// `;

const TimeContainer = styled.div<{ gap: string }>`
  font-size: 14px;
  flex-flow: row wrap;
  display: flex;
  padding: 0 20px 0 0;
  align-items: center;
  gap: ${({ gap }) => gap};
  & > button {
    :disabled {
      background: #e9f0f6;
    }
  }
`;
const CheckboxAndTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DayDiv = styled.div`
  width: 100px;
  margin-top: 10px;
`;

const TableContainer = styled.div`
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th, td {
    padding: 5px;
  }
  th {
    text-align: left;
  }
  margin: 60px 0px;
`;


const AlertBarContainer = styled.div`
  margin: 25px 0px;
  width: 80%;
`;

const NoDataContainer = styled.div`
  margin-top: 20px;
`;

interface IInterval {
  start: Date,
  end: Date
}




const LongParking: FC = () => {
  const { t } = useTranslation(['CommonDict']);
  const [keyword, setKeyword] = useState<string>('');
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [longParkIgnore, setLongParkIgnore] = useState(0);
  const [timeRange, setTimeRange] = useState({startTime: '', endTime: ''});
  const [dayData, setDayData] = useState<any>({startDay: '0', endDay: '0'});
  const [duration, setDuration] = useState<any>({});
  const [resultData, setResultData] = useState<any>([]);
  const [pageContent, setPageContent] = useState<any>([]);
  const [checkedMinMax, setCheckedMinMax] = useState<any>({min: true, max: false});
  

  const [alert, setAlert] = useState<IAlert | null>(null);
    
  const startDate = dateFormat(new Date());
  startDate.setDate(startDate.getDate() - 1);
  const [defaultDate,] = useState<IInterval>({ start: startDate, end: dateFormat(new Date()) });
  const [initialStartDate, setInitialStartDate] = useState<Date>(defaultDate.start);
  const [initialEndDate, setInitialEndDate] = useState<Date>(defaultDate.end);

  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);

  const showTotal: PaginationProps['showTotal'] = (total) => `${t('Total')} ${total} ${t('records')}`;

  



  const onCFToggle = useCallback((e: boolean) => {
    if(e === true){
      setLongParkIgnore(1);
    }else{
      setLongParkIgnore(0);
    }
    
  }, []);

  const updateStartDate = useCallback((date) => {
    if (initialEndDate.getTime() - 60000 <= date.getTime() || date > dateFormat(new Date())) {
      setAlert({
        key: new Date(),
        message: t('Please select \'From\' date smaller then now date and \'To\' date. '),
        type: 'error'
      });
      return;
    }
    setInitialStartDate(date);
  }, [initialEndDate, t]);

  const updateEndDate = useCallback((date) => {
    if (initialStartDate >= date || date > dateFormat(new Date())) {
      setAlert({
        key: new Date(),
        message: t('Please select \'To\' date smaller then now and greater then \'From\' date. '),
        type: 'error'
      });
      return;
    }
    setInitialEndDate(date);
  }, [initialStartDate, t]);

  const handleMinMax = useCallback((val, type) => {
    const copiedObj = structuredClone(checkedMinMax);
    if(type==='min'){
      copiedObj.min = val;
    }
    if(type==='max'){
      copiedObj.max = val;
    }
    setCheckedMinMax(copiedObj);
  },[checkedMinMax]);


  const handleTimePicker = useCallback(async (e, type) => {
    const format = 'HH:mm:ss';
    const copiedTimeRange = {...timeRange};
    if(type==='start'){
      copiedTimeRange.startTime = e!==null ? moment(e.$d).format(format) : '';
    }
    if(type==='end'){
      copiedTimeRange.endTime = e!==null ? moment(e.$d).format(format) : '';
    }
    setTimeRange(copiedTimeRange);
  }, [timeRange]);

  const handleDayData = useCallback(async (e, type) => {
    const copiedDayData = {...dayData};
    if(type==='start'){copiedDayData.startDay = e;}
    if(type==='end'){copiedDayData.endDay = e;}
    setDayData(copiedDayData);
  }, [dayData]);

  function sum2Times(val1:string, val2:string) {
    const result = moment.duration();
    result.add(moment.duration(val1));
    result.add(moment.duration(val2));

    const formatted = result.asSeconds();
    return formatted;
  }


  const handleDuration = useCallback(() => {
    if(checkedMinMax.min){
      duration.min = sum2Times(`${Number(dayData.startDay*24)}:00:00`, timeRange.startTime);
    }
    if(checkedMinMax.max){
      duration.max = sum2Times(`${Number(dayData.endDay*24)}:00:00`, timeRange.endTime);
    }
    if(duration.min > duration.max){
      setAlert({
        key: new Date(),
        message: t('Please select \'Max\' value greater then \'Min\' value.'),
        type: 'error'
      });
    } else{
      setDuration(duration);
    }
  
  },[duration, dayData, timeRange, checkedMinMax, t]);


  useEffect(() => {
    handleDuration();
  }, [handleDuration]);



  

  const onQuerryTbl = useCallback(async () => {
    setTableLoading(true);
    try {
      const response = await searchResult({ keyword: keyword, date_range: {start_date: format(initialStartDate, DATE_FORMAT_WITH_SEC), end_date: format(initialEndDate, DATE_FORMAT_WITH_SEC)}, duration: duration, longParkIgnore: longParkIgnore});
      if(response.status === 200) {
        setResultData(response.data.data);
        setTableLoading(false);
        setIsReadOnly(false);
      } else {
        setTableLoading(false);
        setIsReadOnly(false);
        setAlert({
          key: new Date(),
          message: t('Failed to search for result.'),
          type: 'error'
        });
      }
    } catch {
      setTableLoading(false);
      setIsReadOnly(false);
      setAlert({
        key: new Date(),
        message: t('Failed to search for result.'),
        type: 'error'
      });
    }
  }, [keyword, t, longParkIgnore, initialStartDate, initialEndDate, duration]);

  const handleCheckIgnore = useCallback(async(val, id) => {
    let checked = 0;
    if(val){checked = 1;}else{checked = 0;}
    const payload = {
      id,
      checked
    };
    try {
      const response = await checkIgnore(payload);
      if(response.status === 200) {
        const clone = structuredClone(resultData);
        clone.find((x:any) => x.id === id).long_park_ignore = checked;
        setResultData(clone);
      } else {
        console.log('error occured.');
      }
    } catch {
      console.log('error occured.');
    }
  },[resultData]);

  const handleTableData = useCallback(() => {
    if(resultData.length > 0){
      const arrData = [];
      const copiedObj = [...resultData].slice((page - 1) * pageSize, page * pageSize);

      const formatDuration  = (seconds: number) => {
        const days = Math.floor(seconds / 86400);
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        
        const paddedHours = hours.toString().padStart(2, '0');
        const paddedMinutes = minutes.toString().padStart(2, '0');
        const paddedSeconds = secs.toString().padStart(2, '0');

        const text_day = days>=1 ? `${days}${t('Days')} ` : '';
        const text_tme = `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
      
        return `${text_day}${text_tme}`;
      };
      

      for (let i = 0; i < copiedObj.length; i++) {
        arrData.push({columns: [
          {text: copiedObj[i].place}, 
          {text: copiedObj[i].area}, 
          {text: copiedObj[i].user_type}, 
          {text: copiedObj[i].kana}, 
          {text: copiedObj[i].number}, 
          {text: copiedObj[i].in_camera_name}, 
          //{text: copiedObj[i].in_label_name}, 
          {text: copiedObj[i].in_counted_at},
          {text: copiedObj[i].out_camera_name}, 
          //{text: copiedObj[i].out_label_name}, 
          {text: copiedObj[i].out_counted_at},
          {text: formatDuration(copiedObj[i].parking_time)},
          {customComponent: <div style={{display: 'flex', justifyContent: 'center'}}><Checkbox checked={copiedObj[i].long_park_ignore === 1 ? true : false} onChangeCallback={(e)=>handleCheckIgnore(e, copiedObj[i].id)} /></div>}
        ], id: `row${i+1}`});
      } 
      setPageContent(arrData);
    } else {
      setPageContent([{ columns: [] }]);
    }
  },[resultData, handleCheckIgnore, page, pageSize, t]);


  useEffect(() => {
    handleTableData();
  }, [handleTableData]);

  const columnConfig: any=[
    {
      cellStyle: 'normalImportance',
      header: '駐車場名',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '地域',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '種別',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: 'かな',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '番号',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '入場カメラ',
      alignment: 'center'
    },
    /*{
      cellStyle: 'normalImportance',
      header: '入場検知線',
      alignment: 'center'
    },*/
    {
      cellStyle: 'normalImportance',
      header: '入場時刻',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '退場カメラ',
      alignment: 'center'
    },
    /*{
      cellStyle: 'normalImportance',
      header: '退場検知線',
      alignment: 'center'
    },*/
    {
      cellStyle: 'normalImportance',
      header: '退場時刻',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '滞在時間',
      alignment: 'center'
    },
    {
      alignment: 'center',
      cellStyle: 'normalImportance',
      header: '確認済みフラグ'
    }
  ];

    

  return (
    <Container>
      <HeaderContainer>
        <PageHeaderFormatter>
          <PageHeader
            title={t('Long-term Parking List')}
            icon='FilterSorting'
            introductionText=''
            updateDocTitle={false}
          />
        </PageHeaderFormatter>
        <LastBox>
          <Button design='primary' onClick={onQuerryTbl} size='small'>{t('Search')}</Button>
        </LastBox>
      </HeaderContainer>
      <InnerContainer>
        <>
          {alert &&
            <AlertBarContainer>
              <AlertBar message={alert?.message} type={alert?.type} />
            </AlertBarContainer>}
          <FlexColumn>
            <LabelText style={{'marginTop': '17px'}}>{t('Search By Character')}</LabelText>
            <WrapperInput>
              <TextFieldWrapper>
                <TextField
                  feedbackMessage='This is a feedback message.'
                  fieldState='default'
                  label=''
                  name='my_input'
                  placeholder=''
                  onChange={(e) => { setKeyword(e.target.value); }}
                  disabled={isReadOnly}
                />
              </TextFieldWrapper>
              <SearchIconWrapper>
                <Icon icon='Search' size={17} color='primary' />
              </SearchIconWrapper>
            </WrapperInput>
          </FlexColumn>
          <FlexColumn>
            <LabelText>{t('Time Range Filter')}</LabelText>
            <FlexColumn>
              <DateInput labelText={t('From Date Time')} date={initialStartDate} callBackFunction={updateStartDate} />
              <DateInput labelText={t('To Date Time')} date={initialEndDate} callBackFunction={updateEndDate} />
            </FlexColumn>
          </FlexColumn>
          <FlexColumn>
            <LabelText>{t('Parking Time')}</LabelText>
            <CheckboxAndTimeContainer>
              <TimeContainer gap='0 10px'>
                <div>{t('Min')}</div>
                <Checkbox checked={checkedMinMax.min} onChangeCallback={(e)=>{handleMinMax(e, 'min');}} />
                <div>{t('Max')}</div>
                <Checkbox checked={checkedMinMax.max} onChangeCallback={(e)=>{handleMinMax(e, 'max');}} />
              </TimeContainer>
              <TimeContainer gap='0 20px'>
                {checkedMinMax.min &&
                  <>
                    <DayDiv>
                      <SmallInput
                        fieldState='default'
                        label=''
                        name='my_input'
                        placeholder=''
                        type='text'
                        unit={t('Days')}
                        onChange={(e) => handleDayData(e.target.value, 'start')}
                      />
                    </DayDiv>
                    <TimePicker defaultValue={dayjs('00:00', 'HH:mm')} format={'HH:mm'} onChange={(e)=>{handleTimePicker(e, 'start');}} />
                  </>}
                {(checkedMinMax.min || checkedMinMax.max) && <>~</>}
                {checkedMinMax.max &&
                  <>
                    <DayDiv>
                      <SmallInput
                        fieldState='default'
                        label=''
                        name='my_input'
                        placeholder=''
                        type='text'
                        unit={t('Days')}
                        onChange={(e) => handleDayData(e.target.value, 'end')}
                      />
                    </DayDiv>
                    <TimePicker defaultValue={dayjs('00:00', 'HH:mm')} format={'HH:mm'} onChange={(e)=>{handleTimePicker(e, 'end');}} />
                  </>}
              </TimeContainer>
            </CheckboxAndTimeContainer>
            
          </FlexColumn>
          <FlexColumn style={{'marginTop': '27px'}}>
            <LabelText>{t('Confirmed Flag')}</LabelText>
            <Switch
              checked={longParkIgnore === 1 ? true : false}
              leftTheme='off'
              onChangeCallback={(e) => onCFToggle(e)}
              rightTheme='on'
            //   state={isReadOnly ? 'disabled' : 'default'}
            />
          </FlexColumn>
          <TableContainer>
            <TypeTable
              columnConfig={columnConfig}
              isLoading={tableLoading}
              loadingText='Loading Data..'
              rows={pageContent}
            />
            {resultData.length > 0 ? 
              <Pagination
                current={page}
                size='small'
                pageSize={pageSize}
                onChange={(newPage: any, newPageSize: any) => {
                  setPageSize(newPageSize);
                  setPage(pageSize !== newPageSize ? 1 : newPage);
                }}
                showSizeChanger
                pageSizeOptions={[10, 25, 50]}
                total={resultData.length}
                showTotal={showTotal}
                style={{ marginTop: '18px', textAlign: 'center'}}
              /> : 
              <NoDataContainer>
                <AlertBar type='info' message={t('データがありません。')} />
              </NoDataContainer>}
          </TableContainer>
        </>
      </InnerContainer>
    </Container>
  );
};

export default LongParking;