import { formatDistance, formatDistanceStrict } from 'date-fns';
import { enUS, ja } from 'date-fns/locale';
import i18n from 'i18n';
import { ITrafficCounterData } from 'interface';
import { isEqual, omit } from 'lodash';
import { convertToTimeZone } from 'date-fns-timezone';
import { TIMEZONE } from '../constants';

export const getTimeSince = (date: string) => {
  const lang = i18n.language === 'ja' ? ja : enUS;
  try {
    if (lang === ja) {
      const result = formatDistanceStrict(
        new Date(date), new Date(),
        { locale: ja, addSuffix: false }
      );
      return result.includes('秒') ? '1分未満' : result + '前';
    } else {
      const result = formatDistance(
        new Date(date), new Date(),
        { locale: lang, addSuffix: true, includeSeconds: true }
      );
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
  } catch (err) {
    console.error(err);
  }
  return '-';
};

export const getTimeSinceFromSeconds = (date: number) => {
  if (!date || date === 0)
    return '-';
  const lang = i18n.language === 'ja' ? ja : enUS;
  try {
    if (lang === ja) {
      const result = formatDistanceStrict(
        new Date(date*1000), new Date(),
        { locale: ja, addSuffix: false }
      );
      return result.includes('秒') ? '1分未満' :  result + '前';
    } else {
      const result = formatDistance(
        new Date(date*1000), new Date(),
        { locale: lang, addSuffix: true, includeSeconds: true }
      );
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
  } catch (err) {
    console.error(err);
  }
  return '-';
};

export const dateFormat = (strDate: Date) => {
  try {
    const zonedTargetDate = convertToTimeZone(strDate, { timeZone: TIMEZONE });
    return zonedTargetDate;
  } catch (err) {
    console.error(err);
  }
  return new Date();
};


export const downloadFile = async (url: string) => {
  try {
    const a = document.createElement('a');
    const blobData = await fetch(url);
    a.href = URL.createObjectURL(await blobData.blob());
    a.setAttribute('download', 'Image');
    a.click();
    a.remove();
  } catch (err) {
    console.error(err);
  }
};

export const downloadZipFile = async (url: string) => {
  try{
    if (url !== null && url !== undefined) {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.substr(url.lastIndexOf('/') + 1);
      link.click();
      return true;
    }
  } catch (err) {
    console.error(err);
  }
  return false;
};

export const capitalizeWords = (string: string) => {
  return string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
};

export const unique = (value: string, index: number, self: string []) => {
  return self.indexOf(value) === index;
};

export const compareChanges = (updatedData: ITrafficCounterData, previousData: ITrafficCounterData, key: 'lines'|'analysis_enabled'|'periodic_snapshot_interval') => {
  let changesCount = 0;
  if (key !== 'lines') {
    if (updatedData[key] !== previousData[key]) {
      changesCount += 1;
    }
    return changesCount;
  } else {
    // If any lines has length 0
    if (updatedData.lines.length === 0 || previousData.lines.length === 0) {
      changesCount = changesCount + updatedData.lines.length + previousData.lines.length;
      return changesCount;
    }
    // count lines difference
    const updatedLinesArr = updatedData.lines;
    const previousLinesArr = previousData.lines;
    updatedLinesArr.forEach((line1) => {
      let found = 1;
      for (const line2 of previousLinesArr) {
        if (
          isEqual(omit(line1, ['idx']), omit(line2, ['idx']))
        ) {
          found = 0;
          break;
        }
      }
      changesCount += found;
    });
    if (updatedLinesArr.length < previousLinesArr.length) {
      changesCount += Math.abs(updatedLinesArr.length - previousLinesArr.length);
    }
    return changesCount;
  }
};


export const getStatusColor = (category: string) => {
  switch (category) {
  case 'Running':
    return '#84df9e';
  case 'Error':
    return '#ef7878';
  case 'Warning':
    return '#96acbc';
  case 'Disabled':
    return '#96acbc';
  default:
    return '#96acbc';
  }
};

export const detectBrowser = () => { 
  if((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1 ) {
    return 'Opera';
  } else if(navigator.userAgent.indexOf('Chrome') !== -1 ) {
    return 'Chrome';
  } else if(navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  } else if(navigator.userAgent.indexOf('Firefox') !==-1 ){
    return 'Firefox';
  } else {
    return 'Unknown';
  }
};
