import api from './api';
import {IAddUser, IUpdatePassword} from 'interface';
import TokenService from './tokenService';

// getJWTToken
export const getJWTToken = async () => { 
  return await api.userInstance.get<any>('/generate_token');
};

// user management
export const getAllUsers = async () => { 
  return await api.userInstance.get<any>('/users/all');
};

export const addUser = async (data:IAddUser) => {
  return await api.userInstance.put<any>('/users/add', data);
};

export const getSingleUser = async (data:string) => {
  return await api.userInstance.get<any>(`/users/${data}`);
};

export const updateSingleUser = async (userID:string, data: IAddUser) => {
  return await api.userInstance.patch<any>(`/users/${userID}`,data);
};

export const onDeleteUser = async (data: string) => {
  return await api.userInstance.delete<any>(`/users/${data}`);
};

export const updatePassword = async (data:IUpdatePassword) => {
  // return await api.userInstance.patch<any>(`/users/${TokenService.getUserID()}`,data);
  return await api.userInstance.patch<any>(`/users/${TokenService.getUserID()}`,{data,action: 'update_password'});

};
// Guest User
export const getGuestUser = async () => {
  return await api.userInstance.get<any>('/auth_setting');
};

export const putGuestUpdate = async (data: boolean) => {
  return await api.userInstance.put<any>('/auth_setting', { guest_enabled: data });
};

// get configuration url list
export const getConUrlList = async () => {
  return await api.userInstance.get<any>('/getConUrlList');
};

// -- Tien -- //
export const getAllParkingZone = async () => {
  return await api.userInstance.get<any>('/allparkingzone');
};

export const getAllParkingSlot = async () => {
  return await api.userInstance.get<any>('/allparkingslot');
};

export const getAllRoadwayZone = async () => {
  return await api.userInstance.get<any>('/allroadwayzone');
};

export const getAllArea = async () => {
  return await api.userInstance.get<any>('/allarea');
};

export const saveSelectedData = async (param:any) => {  
  return await api.userInstance.post<any>('/saveSelectedData', param);
};

export const loadSelectedData = async () => {
  return await api.userInstance.get<any>('/loadSelectedData');
};
// -- Tien -- //


export const getLocationData = async () => {
  return await api.userInstance.get<any>('/getLocationData');
};


export const saveLocationData = async (param:any) => {
  return await api.userInstance.post<any>('/saveLocationData', param);
};




//query long term parking
export const searchResult = async (params:any) => {
  return await api.userInstance.get<any>('searchResult', {params});
};

//check ignore button
export const checkIgnore = async (param:any) => {
  return await api.userInstance.post<any>('checkIgnore', param);
};


// -- Tien - 車番活用 - 2024.02 start -- //
export const getParkingHistory = async (params:any) => {
  return await api.userInstance.get<any>('/parkinghistory', {params});
};
// -- Tien - 車番活用 - 2024.02 end -- //



export const getAllCamera = async () => {
  return await api.userInstance.get<any>('/cameras/all', { timeout: 30000 });
};
