import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Content, FilterDropdown, Label, PageTitle, Spinner, useNotification, usePoll } from 'scorer-ui-kit';
import styled from 'styled-components';
import { getAllArea, getAllParkingSlot, getAllParkingZone, getAllRoadwayZone, loadSelectedData, saveSelectedData } from 'services/apiConfig';
import AuthService from 'services/authService';
import { SpinnerContainer } from 'style';
import CardZone from 'components/CardZone';
import CardSlot from 'components/CardSlot';
import CardRoad from 'components/CardRoad';
import CardCount from 'components/CardCount';



const Container = styled(Content)`
  @media only screen and (max-width: 1440px) {
    max-width: 940px;
  }
  & > div {
    max-width: 940px;
  }
  user-select: none;
  padding: 0px 150px 40px;
`;

const HeaderBar = styled.div`
  @media only screen and (width: 1920px) {
    max-width: 1421px !important;
  }
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > div > div > div{
    bottom: 2px;
  }
  margin-left: -110px;
  margin-bottom: -30px;
  margin-top: 50px;
`;

const FilterContainer = styled.div`
  @media only screen and (width: 1920px) {
    min-width: 940px !important;
  }
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin: 24px 0 22px 0px;
  & > div > div > div:nth-child(2) > div{
    max-width: 214px;
  }
  & > div > div > div:nth-child(3){
    margin-top: 15px;
    margin-right: 233px;
  }
`;

const CameraListBox = styled.div`
  @media only screen and (width: 1920px) {
    min-width: 940px !important;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const EmptyBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  border: solid 1px #cdcdcd;
  padding: 37px 0;
  @media only screen and (min-width: 1439px) {
    max-width: 940px !important;
  }
`;

const EmptyStateTitle = styled(Label)`
  font-size: 20px;
  margin-bottom: 0;
`;

const SpinnerBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const StyleFilterDropdown = styled.div`
  & > div > div > div > button {
    height: 40px;
    font-size: 16px;
    & > div {
    width: 700px;
    flex-direction: row;
    & > div:nth-child(3) {
      justify-content: flex-start;
      margin-left: auto;
    }
  }
  }
`;

type ParkingType = {
  id: number;
  count: number;
  device_name: string;
  location_name: string;
  name: string;
  stream_name: string;
  max_slot: number;
  graph_data: { date: string; count: number }[];
};


const DATA_REFRESH_INTERVAL = 600;  // 1min
const Camera: FC = () => {
  const { t } = useTranslation(['CommonDict']);
  const tRef = useRef(t);
  const [loading, setLoading] = useState<boolean>(false);
  const { sendNotification } = useNotification();
  const notificationRef = useRef(sendNotification);
  const [isDataFetched, setIsDataFetched] = useState<boolean>(true);

  // ----------------- Tien start ------------------------ //
  // ----------------- for 区画満空 1-2-3 ----------------- //
  const [parkingZoneList, setParkingZoneList] = useState([{'text': '', 'value': ''}]);
  const [allParkingZone, setAllParkingZone] = useState([]);
  const [selectedZone, setSelectedZone] = useState<any>([]);
  const [zoneData, setZoneData] = useState<any>([]);

  // ----------------- for 車室満空 4-5-6 ----------------- //
  const [parkingSlotList, setParkingSlotList] = useState([{'text': '', 'value': ''}]);
  const [allParkingSlot, setAllParkingSlot] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState<any>([]);
  const [slotData, setSlotData] = useState<any>([]);

  // ----------------- for エリアカウント 7-8-9 ----------------- //
  const [areaList, setAreaList] = useState([{'text': '', 'value': ''}]);
  const [allArea, setAllArea] = useState([]);
  const [selectedArea, setSelectedArea] = useState<any>([]);
  const [areaData, setAreaData] = useState<any>([]);

  // ----------------- for 車路 10-11-12 ----------------- //
  const [roadWayZoneList, setRoadwayZoneList] = useState([{'text': '', 'value': ''}]);
  const [allRoadwayZone, setAllRoadwayZone] = useState([]);
  const [selectedRoadwayZone, setSelectedRoadwayZone] = useState<any>([]);
  const [roadWayZoneData, setRoadWayZoneData] = useState<any>([]);

  // ------------- get all parking zone info (1-2-3) ------------- //
  const fetchAllParkingZone = useCallback(async () => {
    setIsDataFetched(false);
    setLoading(true);
    try {      
      const { data: { status_code, data } } = await getAllParkingZone();
      if (status_code === 200) {
        setAllParkingZone(data);
        const tmp = data.map((item: any) => item.name);
        setParkingZoneList(tmp.map((item: any) => ({
          text: item,
          value: item,
        })));
        setLoading(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        setLoading(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load parking zone data') });
      }
    } catch (error) {
      setLoading(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to load parking zone data') });
      console.error((error as Error).message);
    }
    setIsDataFetched(true);
  }, []);

  // useEffect(() => {
  //   fetchAllParkingZone();
  // }, [fetchAllParkingZone]);  
  usePoll(() => {
    fetchAllParkingZone();  
  }, DATA_REFRESH_INTERVAL * 1000);


  // ------------- get all parking slot info (4-5-6) ------------- //
  const fetchAllParkingSlot = useCallback(async () => {
    setIsDataFetched(false);
    setLoading(true);
    try {      
      const { data: { status_code, data } } = await getAllParkingSlot();
      if (status_code === 200) {
        setAllParkingSlot(data);
        const tmp = data.map((item: any) => item.name);
        setParkingSlotList(tmp.map((item: any) => ({
          text: item,
          value: item,
        })));
        setLoading(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        setLoading(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load parking slot data') });
      }
    } catch (error) {
      setLoading(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to load parking slot data') });
      console.error((error as Error).message);
    }
    setIsDataFetched(true);
  }, []);

  // useEffect(() => {
  //   fetchAllParkingSlot();
  // }, [fetchAllParkingSlot]);
  usePoll(() => {
    fetchAllParkingSlot();  
  }, DATA_REFRESH_INTERVAL * 1000);


  // ------------- get all area info (7-8-9) ------------- //
  const fetchAllArea = useCallback(async () => {
    setIsDataFetched(false);
    setLoading(true);
    try {      
      const { data: { status_code, data } } = await getAllArea();
      if (status_code === 200) {
        setAllArea(data);
        const tmp = data.map((item: any) => item.name);
        setAreaList(tmp.map((item: any) => ({
          text: item,
          value: item,
        })));
        setLoading(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        setLoading(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load area data') });
      }
    } catch (error) {
      setLoading(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to load area data') });
      console.error((error as Error).message);
    }
    setIsDataFetched(true);
  }, []);

  // useEffect(() => {
  //   fetchAllArea();
  // }, [fetchAllArea]);
  usePoll(() => {
    fetchAllArea();  
  }, DATA_REFRESH_INTERVAL * 1000);
  

  // ------------- get all roadway zone info (10-11-12) ------------- //
  const fetchAllRoadwayZone = useCallback(async () => {
    setIsDataFetched(false);
    setLoading(true);
    try {      
      const { data: { status_code, data } } = await getAllRoadwayZone();
      if (status_code === 200) {
        setAllRoadwayZone(data);
        const tmp = data.map((item: any) => item.name);
        setRoadwayZoneList(tmp.map((item: any) => ({
          text: item,
          value: item,
        })));
        setLoading(false);
      } else if (status_code === 403) {
        notificationRef.current({ type: 'error', message: tRef.current('Authorization required to access') });
        if (await AuthService.logoutUser() !== 200) {
          notificationRef.current({ type: 'error', message: tRef.current('Failed to communicate with the system') });
        }
      }
      else {
        setLoading(false);
        notificationRef.current({ type: 'error', message: tRef.current('Failed to load roadway zone data') });
      }
    } catch (error) {
      setLoading(false);
      notificationRef.current({ type: 'error', message: tRef.current('Failed to load roadway zone data') });
      console.error((error as Error).message);
    }
    setIsDataFetched(true);
  }, []);

  // useEffect(() => {
  //   fetchAllRoadwayZone();
  // }, [fetchAllRoadwayZone]);
  usePoll(() => {
    fetchAllRoadwayZone();  
  }, DATA_REFRESH_INTERVAL * 1000);
  

  // ----------------- handle functions ----------------- //
  // ----------------- handle save to json ----------------- //
  const saveJsonData = useCallback(async (selectedZone, selectedSlot, selectedRoadwayZone, selectedArea) => {
    try {
      await saveSelectedData({ selectedZone, selectedSlot, selectedRoadwayZone, selectedArea });
    } catch (error) {
      console.error('Failed to save data', error);
    }
  }, []);

  const loadJsonData = useCallback(async () => {
    try {
      const response = await loadSelectedData();
      if(response) {
        const { selectedZone, selectedSlot, selectedArea, selectedRoadwayZone } = response.data;      
        setSelectedZone(selectedZone);
        setSelectedSlot(selectedSlot);
        setSelectedRoadwayZone(selectedRoadwayZone);
        setSelectedArea(selectedArea);
        setZoneData(allParkingZone.filter((item: any) => selectedZone?.map((zone: any) => zone.value).includes(item.name)));
        setSlotData(allParkingSlot.filter((item: any) => selectedSlot?.map((slot: any) => slot.value).includes(item.name)));
        setRoadWayZoneData(allRoadwayZone.filter((item: any) => selectedRoadwayZone?.map((rwzone: any) => rwzone.value).includes(item.name)));
        setAreaData(allArea.filter((item: any) => selectedArea?.map((area: any) => area.value).includes(item.name)));
      } else {
        notificationRef.current({ type: 'info', message: tRef.current('Please select your item.') });
      }     
    } catch (error) {
      console.error('Failed to load data', error);
    }
  }, [allParkingZone, allArea, allParkingSlot, allRoadwayZone]);

  useEffect(() => {
    loadJsonData();
  }, [loadJsonData]);

  // ----------------- for 区画満空 1-2-3 ----------------- //
  const handleChangeZone = useCallback((selection) => {   
    const newSelection = selection ? [...selection] : [];
    setSelectedZone(newSelection);
    saveJsonData(newSelection, selectedSlot, selectedRoadwayZone, selectedArea);
  },[saveJsonData, selectedArea, selectedRoadwayZone, selectedSlot]);

  useEffect(() => {
    let filteredData = [];
    if (selectedZone === 0){
      filteredData = allParkingZone.filter((item: any) => parkingZoneList.map((item: any) => item.value).includes(item.name));
    }else{
      filteredData = allParkingZone.filter((item: any) => selectedZone?.map((item: any) => item.value).includes(item.name));
    }
    setZoneData(filteredData);
  }, [selectedZone, parkingZoneList, allParkingZone]);
  
  // ----------------- for 車室満空 4-5-6 ----------------- //
  const handleChangeSlot = useCallback((selection) => {
    const newSelection = selection ? [...selection] : [];
    setSelectedSlot(newSelection);
    saveJsonData(selectedZone, newSelection, selectedRoadwayZone, selectedArea);
  },[saveJsonData, selectedArea, selectedRoadwayZone, selectedZone]);

  useEffect(() => {
    let filteredData = [];
    if (selectedSlot === 0){
      filteredData = allParkingSlot.filter((item: any) => parkingSlotList.map((item: any) => item.value).includes(item.name));
    }else{
      filteredData = allParkingSlot.filter((item: any) => selectedSlot?.map((item: any) => item.value).includes(item.name));
    }
    setSlotData(filteredData);
  }, [selectedSlot, parkingSlotList, allParkingSlot]);

  // ----------------- for エリアカウント 7-8-9 ----------------- //
  const handleChangeArea = useCallback((selection) => {
    const newSelection = selection ? [...selection] : [];
    setSelectedArea(newSelection);
    saveJsonData(selectedZone, selectedSlot, selectedRoadwayZone, newSelection);
  },[saveJsonData, selectedZone, selectedSlot, selectedRoadwayZone]);

  useEffect(() => {
    let filteredData = [];
    if (selectedArea === 0){
      filteredData = allArea.filter((item: any) => areaList.map((item: any) => item.value).includes(item.name));
    }else{
      filteredData = allArea.filter((item: any) => selectedArea?.map((item: any) => item.value).includes(item.name));
    }
    setAreaData(filteredData);
  }, [selectedArea, areaList, allArea]);

  // ----------------- for 車路 10-11-12 ----------------- //
  const handleChangeRoadwayZone = useCallback((selection) => {    
    const newSelection = selection ? [...selection] : [];
    setSelectedRoadwayZone(newSelection);
    saveJsonData(selectedZone, selectedSlot, newSelection, selectedArea);
  },[saveJsonData, selectedZone, selectedSlot, selectedArea]);

  useEffect(() => {
    let filteredData = [];
    if (selectedRoadwayZone === 0){
      filteredData = allRoadwayZone.filter((item: any) => roadWayZoneList.map((item: any) => item.value).includes(item.name));
    }else{
      filteredData = allRoadwayZone.filter((item: any) => selectedRoadwayZone?.map((item: any) => item.value).includes(item.name));
    }
    setRoadWayZoneData(filteredData);
  }, [selectedRoadwayZone, roadWayZoneList, allRoadwayZone]); 
  
  // ----------------- Tien end ----------------- //

  if (loading) {
    return (
      <SpinnerBox>
        <Spinner size='large' styling='primary' />
        <Label htmlFor='loader' labelText={t('Loading') + '...'} />
      </SpinnerBox>
    );
  }  
  
  return (
    <>
      {isDataFetched ? (
        <Container>
          {/* -------------------- 区画満空 1-2-3 -------------------- */}
          <HeaderBar>
            <div>
              <PageTitle
                title={t('区画満空')}
                updateDocTitle={false}
              />
            </div>
          </HeaderBar>
          <FilterContainer>
            <StyleFilterDropdown>
              <FilterDropdown
                buttonIcon='MetaCategories'
                buttonText={selectedZone === 0 ? '全区画' : selectedZone.length === 0 ? '区画を選択してください' : selectedZone?.map((item: any) => item.text).join('，')}
                list={parkingZoneList}
                loadingText='ローディング...'
                maxDisplayedItems={6}
                onSelect={handleChangeZone}
                optionType='checkbox'
                selected={selectedZone === 0 ? [] : selectedZone}
                hasOptionsFilter
                searchPlaceholder='検索...'
                searchResultText='[VISIBLE] 〜 [TOTAL]件'
              />
            </StyleFilterDropdown>
            <Button onClick={()=>setSelectedZone(0)}>{t('全区画表示')}</Button>
          </FilterContainer>

          {zoneData.length > 0 ?
            <CameraListBox>
              {zoneData.map((item: any, index: number) => (
                <CardZone key={index} data={item} />
              ))}
            </CameraListBox>
            :
            <>
              <EmptyBox>
                <EmptyStateTitle htmlFor='' labelText={t('区画が選択されていません')} />
              </EmptyBox>            
            </>}

          {/* -------------------- 車室満空 4-5-6 --------------------  */}
          <HeaderBar>
            <div>
              <PageTitle
                title={t('車室満空')}
                updateDocTitle={false}
              />
            </div>
          </HeaderBar>
          <FilterContainer>
            <StyleFilterDropdown>
              <FilterDropdown
                buttonIcon='MetaCategories'
                buttonText={selectedSlot === 0 ? '全車室' : selectedSlot.length === 0 ? '車室を選択してください' : selectedSlot?.map((item: any) => item.text).join('，')}
                list={parkingSlotList}
                loadingText='ローディング...'
                maxDisplayedItems={6}
                onSelect={handleChangeSlot}
                optionType='checkbox'
                selected={selectedSlot}
                hasOptionsFilter
                searchPlaceholder='検索...'
                searchResultText='[VISIBLE] 〜 [TOTAL]件'              
              />
            </StyleFilterDropdown>
            <Button onClick={()=>setSelectedSlot(0)}>{t('全車室表示')}</Button>
          </FilterContainer>

          {slotData.length > 0 ?
            <CameraListBox>
              {slotData.map((item: any, index: number) => (
                <CardSlot key={index} data={item} />
              ))}
            </CameraListBox>
            :
            <>
              <EmptyBox>
                <EmptyStateTitle htmlFor='' labelText={t('車室が選択されていません')} />
              </EmptyBox>            
            </>}

          {/* -------------------- 車路 10-11-12 --------------------  */}
          <HeaderBar>
            <div>
              <PageTitle
                title={t('車路')}
                updateDocTitle={false}
              />
            </div>
          </HeaderBar>
          <FilterContainer>
            <StyleFilterDropdown>
              <FilterDropdown
                buttonIcon='MetaCategories'
                buttonText={selectedRoadwayZone === 0 ? '全車路' : selectedRoadwayZone.length === 0 ? '車路を選択してください' : selectedRoadwayZone?.map((item: any) => item.text).join('，')}
                list={roadWayZoneList}
                loadingText='ローディング...'
                maxDisplayedItems={6}
                onSelect={handleChangeRoadwayZone}
                optionType='checkbox'
                selected={selectedRoadwayZone}
                hasOptionsFilter
                searchPlaceholder='検索...'
                searchResultText='[VISIBLE] 〜 [TOTAL]件'              
              />
            </StyleFilterDropdown>
            <Button onClick={()=>setSelectedRoadwayZone(0)}>{t('全車路表示')}</Button>
          </FilterContainer>

          {roadWayZoneData.length > 0 ?
            <CameraListBox>
              {roadWayZoneData.map((item: any, index: number) => (
                <CardRoad key={index} data={item} />
              ))}
            </CameraListBox>
            :
            <>
              <EmptyBox>
                <EmptyStateTitle htmlFor='' labelText={t('車路が選択されていません')} />
              </EmptyBox>            
            </>}

          {/* -------------------- カウント満空 7-8-9 --------------------  */}
          <HeaderBar>
            <div>
              <PageTitle
                title={t('エリア')}
                updateDocTitle={false}
              />
            </div>
          </HeaderBar>
          <FilterContainer>
            <StyleFilterDropdown>
              <FilterDropdown
                buttonIcon='MetaCategories'
                buttonText={selectedArea === 0 ? '全エリア' : selectedArea.length === 0 ? 'エリアを選択してください' : selectedArea?.map((item: any) => item.text).join('，')}
                list={areaList}
                loadingText='ローディング...'
                maxDisplayedItems={6}
                onSelect={handleChangeArea}
                optionType='checkbox'
                selected={selectedArea}
                hasOptionsFilter
                searchPlaceholder='検索...'
                searchResultText='[VISIBLE] 〜 [TOTAL]件'              
              />
            </StyleFilterDropdown>
            <Button onClick={()=>setSelectedArea(0)}>{t('全エリア表示')}</Button>
          </FilterContainer>

          {areaData.length > 0 ?
            <CameraListBox>
              {areaData.map((item: any, index: number) => (
                <CardCount key={index} data={item} />
              ))}
            </CameraListBox>
            :
            <>
              <EmptyBox>
                <EmptyStateTitle htmlFor='' labelText={t('エリアが選択されていません')} />
              </EmptyBox>            
            </>}

        </Container>
      ) : (
        <SpinnerContainer>
          <Spinner size='medium' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerContainer>
      )}
    </>

  );
};

export default Camera;