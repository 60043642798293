import React, { useCallback, useEffect, useState } from 'react';
import IframeComponent from 'components/Iframe';
import { getJWTToken } from 'services/apiConfig';

function Configuration({iframe_src}: {iframe_src: string }){
  const [iframeUrl, setIframeUrl] = useState(iframe_src);
  const getToken = useCallback(async () => {
    const response = await getJWTToken();
    setIframeUrl(`${iframe_src}?token=${response.data.token}`);
  }, [iframe_src]);
  useEffect(() => {
    getToken();
  }, [getToken]);

  if (!iframeUrl) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <IframeComponent
        src={iframeUrl}
        width='100%'
        height='2000px'
      />
    </div>
  );
}

export default Configuration;
