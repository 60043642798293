const HOST = window.location.hostname;
export const SERVER_URL = window.location.protocol + '//' + HOST;

export const BASE_API_URL = '/api/';
export const EDGE_API_BASE_URL = '/edge/api/v1/';
// export const BASE_API_URL = SERVER_URL + ':3500';  // SDK test
// export const EDGE_API_BASE_URL = SERVER_URL + ':21001/api/v1/';  // SDK test

export const SUPERSET_URL = '/viz/superset/';
export const SUPERSET_URL_DASHBOARD_1 = '/viz/superset/dashboard/1/';
export const SUPERSET_URL_DASHBOARD_2 = '/viz/superset/dashboard/2/';
export const SUPERSET_URL_DASHBOARD_3 = '/viz/superset/dashboard/3/';


export const DATE_FORMAT_WITH_SEC = 'yyyy-MM-dd HH:mm:ss';
export const TIMEZONE = 'Asia/Tokyo';